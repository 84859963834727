<template>
  <div class="about">
    <!--====== PAGE TITLE PART START ======-->

    <div class="page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-item text-center">
              <h2 class="title">HIRE OUR DEVELOPERS</h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">kibbutztech</router-link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====== PAGE TITLE PART ENDS ======-->

    <!--====== WHO WE ARE PART START ======-->

    <div class="who-we-are-area pt-110 pb-120">
      <div class="container">
        <div class="row">
          <div class="row">
            <div
              onclick="redirect_page('Hire_React_Native_Developers')"
              class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
              data-wow-duration=".5s"
              data-wow-delay="0s"
            >
              <router-link to="/hire-details?name=Hire_React_Native_Developers">
                <!-- <a href="hire-details?name=Hire_React_Native_Developers"> -->
                <div style="height:300px" class="what-we-do-item text-center mt-30">
                  <i aria-hidden="true">
                    <img src="assets/images/react_icon.png" alt />
                  </i>
                  <h5 class="title">Hire our React Native Developers</h5>
                  <p>
                    Raise the bar of mobile apps with React
                    Native
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>

            <div
              class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
              data-wow-duration=".5s"
              data-wow-delay="0s"
            >
              <router-link to="/hire-details?name=Hire_Vue_Developers">
                <!-- <a href="hire-details?name=Hire_Vue_Developers"> -->
                <div style="height:300px" class="what-we-do-item text-center mt-30">
                  <i aria-hidden="true">
                    <img src="assets/images/vue_icon.png" alt />
                  </i>
                  <h5 class="title">Hire our Vue.js Developers</h5>
                  <p>Enhance your applications with Vue.js</p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>

            <div
              onclick="redirect_page('Hire_Node_Developers')"
              class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
              data-wow-duration="1s"
              data-wow-delay="0s"
            >
              <router-link to="/hire-details?name=Hire_Node_Developers">
                <!-- <a href="hire-details?name=Hire_Node_Developers"> -->
                <div style="height:300px" class="what-we-do-item text-center mt-30">
                  <i aria-hidden="true">
                    <img src="assets/images/node_icon.png" alt />
                  </i>
                  <!-- <i class="fal fa-fingerprint"></i> -->
                  <h5 class="title">Hire our Node.js Developers</h5>
                  <p>
                    Be a long-term player in business with Node.js
                    Developers
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <div
              onclick="redirect_page('Hire_React_Developers')"
              class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
              data-wow-duration="2s"
              data-wow-delay="0s"
            >
              <router-link to="/hire-details?name=Hire_React_Developers">
                <!-- <a href="hire-details?name=Hire_React_Developers"> -->
                <div style="height:300px" class="what-we-do-item text-center mt-30">
                  <i aria-hidden="true">
                    <img src="assets/images/react_icon.png" alt />
                  </i>
                  <h5 class="title">Hire our React.js Developers</h5>
                  <p>
                    Create the history of excellence on the web
                    with React.js
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
          </div>

          <div class="row">
            <div
              onclick="redirect_page('Hire_Android_Developers')"
              class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
              data-wow-duration="1s"
              data-wow-delay="0s"
            >
              <router-link to="/hire-details?name=Hire_Android_Developers">
                <!-- <a href="hire-details?name=Hire_Android_Developers"> -->
                <div style="height:300px" class="what-we-do-item text-center mt-30">
                  <i aria-hidden="true">
                    <img src="assets/images/android_icon.png" alt />
                  </i>
                  <h5 class="title">Hire our Android Developers</h5>
                  <p>
                    Let Your Business Reach New Heights with
                    Android's Popularity
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <div
              onclick="redirect_page('Hire_iOS_Developers')"
              class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
              data-wow-duration="1.5s"
              data-wow-delay="0s"
            >
              <router-link to="/hire-details?name=Hire_iOS_Developers">
                <!-- <a href="hire-details?name=Hire_iOS_Developers"> -->
                <div style="height:300px" class="what-we-do-item text-center mt-30">
                  <i aria-hidden="true">
                    <img src="assets/images/apple_icon.png" alt />
                  </i>
                  <h5 class="title">Hire our iOS Developers</h5>
                  <p>
                    Create apps for some of the most dependable
                    mobile devices
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>

            <div
              onclick="redirect_page('Hire_Laravel_Developers')"
              class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
              data-wow-duration="1.5s"
              data-wow-delay="0s"
            >
              <router-link to="/hire-details?name=Hire_Laravel_Developers">
                <!-- <a href="hire-details?name=Hire_Laravel_Developers"> -->
                <div style="height:300px" class="what-we-do-item text-center mt-30">
                  <i aria-hidden="true">
                    <img src="assets/images/laravel_icon.png" alt />
                  </i>
                  <h5 class="title">Hire our Laravel Developers</h5>
                  <p>
                    Create a new dimension to your business with
                    Laravel technology
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>

            <div
              onclick="redirect_page('Hire_flutter_Developers')"
              class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
              data-wow-duration="1.5s"
              data-wow-delay="0s"
            >
              <router-link to="/hire-details?name=Hire_flutter_Developers">
                <!-- <a href="hire-details?name=Hire_Laravel_Developers"> -->
                <div style="height:300px" class="what-we-do-item text-center mt-30">
                  <i aria-hidden="true">
                    <img src="assets/images/flutter_icon.png" alt />
                  </i>
                  <h5 class="title">Hire our Flutter Developers</h5>
                  <p>Shine like gold in your business with Flutter</p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====== WHO WE ARE PART ENDS ======-->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    // console.log("this.$route.params",this.$route.params.name)
    window.scrollTo(0, 0);
  }
};
</script>

<style >
.who-we-are-area .container a{ 
  width: 100% !important;
}

</style>